import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getUserData,
    //setImage,
    //setRecordData,
    //setVideoData,
    //selectImage,
    //selectProfile,
    selectExperience,
    changeScreen,
    //selectCapturedVideo,
    getDesignConfig,
    setUser,
} from '../features/studio/studioSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {  CopyRights } from '../components';
//import Resumable from 'resumablejs';
//import { ProgressBar } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { useForm } from 'react-hook-form';
import * as animationData from '@src/assets/lottie/7877-uploading-to-cloud.json';
import MainLayout from '@src/layout/MainLayout';
import { Laravel } from '..';
//import { type } from '@testing-library/user-event/dist/type';

export const Legal = () => {

    const storiurl = process.env.REACT_APP_HUB_URL;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const splitName = (fullname) => {
        let spaceIndex = fullname.indexOf(' ');
        let firstName;
        let lastName;

        if (spaceIndex == -1) {
            firstName = fullname;
            lastName = '';
        } else {
            firstName = fullname.substring(0, spaceIndex);
            lastName = fullname.substr(spaceIndex + 1);
        }

        return {
            firstName,
            lastName,
        };
    }

    //2 mb chunk size
    //const chunkSize = 1048576 * 1;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const videos = useSelector(selectCapturedVideo);
    //const image = useSelector(selectImage);
    //const profile = useSelector(selectProfile);
    const experience = useSelector(selectExperience);
    const [uploading, setUploading] = useState(false);
    //const [progress, setProgress] = useState(0);
    const [agree, setAgree] = useState(false);
    const [terms, displayTerms] = useState(false);
    // const previewSize = useSelector(selectPreviewSize);
    const userData = useSelector(getUserData); //;
    const designConfig = useSelector(getDesignConfig);
    const mosaic_id = designConfig.mosaic_id;
    //
    const attribute_headline = (designConfig.attribute_headline) ? designConfig.attribute_headline : 'Well done!';
    const attribute_message = (designConfig.attribute_message) ? designConfig.attribute_message : 'We just need some details to finalize your ShoutOut for Company Name.';
    const attribute_show_jobtitle_field = (designConfig.attribute_show_jobtitle_field) ? designConfig.attribute_show_jobtitle_field : '';
    const attribute_show_companyname = (designConfig.attribute_show_companyname) ? designConfig.attribute_show_companyname : '';
    const attribute_show_mobilephone = (designConfig.attribute_show_mobilephone) ? designConfig.attribute_show_mobilephone : '';
    const display_term_popup = (terms) ? 'block' : 'none';
    //console.log('designConfig');
    //console.log(designConfig);
    //console.log(userData);
    //
    //console.log();
    //
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) { // eslint-disable-line no-unused-vars
            // let percentCompleted = Math.round(
            // 	(progressEvent.loaded * 100) / progressEvent.total
            // );
            // setProgress(percentCompleted);
            // console.log(percentCompleted);
        },
    };

    const onSubmit = (data) => {
  
        console.log(data);

        let { firstName, lastName } = splitName(data.fullName);

        const newdata = {
            firstName : firstName,
            lastName : lastName,
            email: data.email,
            jobTitle : data.jobTitle,
            companyName : data.companyName,
            mobileNumber : data.mobileNumber,
            mosaic_id: Laravel.global_mosaic_id,
        };

        //alert(firstName + '-' + lastName);
        dispatch(
            setUser(newdata)
        );

        //console.log(profile);

        let shoutout_question_responses_ids = localStorage.getItem('shoutout_question_responses_ids');


        const formData = new FormData();
            formData.append('social_user', data.fullName);
            formData.append('firstName', newdata.firstName);
            formData.append('lastName', newdata.lastName);
            formData.append('social_user_email', newdata.email);
            formData.append('jobTitle', newdata.jobTitle);
            formData.append('companyName', newdata.companyName);
            formData.append('mobileNumber', newdata.mobileNumber);
            formData.append('experience', experience);
            formData.append('mosaic_id', mosaic_id);
            formData.append('shoutout_question_responses_ids', shoutout_question_responses_ids);

            setUploading(true);
            
        axios
            .post(`${storiurl}/api/save_shoutout_legal_form`, formData, config)
            .then((res) => {
                console.log(res);
                let data = res.data;
                if (data.success) {
                    //dispatch(setImage(data.record.social_image_url));
                    //dispatch(setRecordData(data.record));

                    // it means all question done & all data saved
                    // so delete all storage vars
                    // unset storage variabels
                    // navigate to final page
                    localStorage.removeItem('cur_q_num');
                    localStorage.removeItem('cur_q');
                    localStorage.removeItem('cur_q_id');
                    //
                    localStorage.removeItem('initial_minute');
                    localStorage.removeItem('initial_seconds');
                    //
                    localStorage.removeItem('shoutout_question_responses_ids');
                    //
                    localStorage.removeItem('max_question_video_duration');
                    //
                    setUploading(false);
                    dispatch(changeScreen('thanks'));
                    navigate('/thanks');
                } else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                setUploading(false);
                console.log(err);
                alert('File Upload Error');
            });
        
        //handleUpload(newdata);
    };

    const showJobTitleField = () => {
        //console.log("attribute_show_jobtitle_field: " + attribute_show_jobtitle_field);
        if(attribute_show_jobtitle_field){
            return (
                <div className='form-group'>
                    <input
                        className='form-control'
                        placeholder='Job Title'
                        {...register('jobTitle', {
                            required: false,
                            maxLength: 30,
                        })}
                    />
                </div>
            );
        } else {
            return (
                <input type="hidden" name="jobTitle" {...register('jobTitle')} />
            );
        }
    };
    const showCompanyNameField = () => {
        //console.log("attribute_show_companyname: " + attribute_show_companyname);
        if(attribute_show_companyname){
            return (
                <div className='form-group'>
                    <input
                        className='form-control'
                        placeholder='Company Name'
                        {...register('companyName', {
                            required: false,
                            maxLength: 30,
                        })}
                    />
                </div>
            );
        } else {
            return (
                <input type="hidden" name="companyName" {...register('companyName')} />
            );
        }
    };
    
    const showPhoneField = () => {
        //console.log("attribute_show_mobilephone: " + attribute_show_mobilephone);
        if(attribute_show_mobilephone){
            return (
                <div className='form-group'>
                    <input
                        className='form-control'
                        placeholder='Mobile Number'
                        {...register('mobileNumber', {
                            required: false,
                            maxLength: 30,
                        })}
                    />
                </div>
            );
        } else {
            return (
                <input type="hidden" name="mobileNumber" {...register('mobileNumber')} />
            );
        }
    };

    const hideTermPopup = () => {
        displayTerms(false);
    };
    const showTermPopup = () => {
        displayTerms(true);
    };

    //console.log('display_term_popup: ' + display_term_popup);

    //-----------------------------------------------------------------------//

    return (
        <MainLayout>
            <div className='shoutout_content_submit'>
                {!uploading && (
              
                    <div className='shoutout_submit_wrap'>

                        <div className='shoutout_submit_content'>
                            <h2>{attribute_headline}</h2>
                            <p>{attribute_message}</p>
                            {/* <a href='/agreement' target='_blank'>
                      <i className='fas fa-file'></i> View content submission
                      agreement.
                    </a> */}
                        </div>
                        <form id="shoutoutLegalForm" name="shoutoutLegalForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-group'>
                                <input
                                    className={
                                        errors.fullName
                                            ? 'form-control input-danger'
                                            : 'form-control'
                                    }
                                    placeholder='Full Name' 
                                    defaultValue=''
                                    {...register('fullName', {
                                        required: true,
                                        maxLength: 30,
                                    })}
                                />
                                {errors.fullName && (
                                    <p className='danger'>Please enter Full Name</p>
                                )}
                            </div>

                            <div className='form-group'>
                                <input
                                    className={
                                        errors.email
                                            ? 'form-control input-danger'
                                            : 'form-control'
                                    }
                                    type='email'
                                    placeholder='Email Address'
                                    defaultValue=''
                                    {...register('email', {
                                        required: true,
                                        pattern:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,  // eslint-disable-line
                                    })}
                                />
                                {errors.email && (
                                    <p className='danger'>Please enter correct email.</p>
                                )}
                            </div>

                            {showJobTitleField()}
                            {showCompanyNameField()}
                            {showPhoneField()}

                            <div className='form-group'>
                            </div>

                            <div className='form-group'>
                                <div className='checkbox'>
                                    <label>
                                        <input
                                            type='checkbox'
                                            value='1'
                                            onChange={() => setAgree(!agree)}
                                        />
                                        
                                        {/* https://stori.net/privacy-policy/ */}
                                        <span>I agree to the <a rel='noreferrer' href='#' onClick={() => showTermPopup()}>Content Submission Agreement</a></span>
                                    </label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-lg btn-block'
                                    disabled={!agree || uploading}
                                    /* onClick={() => handleUpload()} */
                                    style={{
                                        backgroundColor: designConfig.btn_bg_color,
                                        borderColor: designConfig.btn_bg_color,
                                        color: designConfig.btn_text_color,
                                    }}>
                                    {!uploading && 'SUBMIT SHOUTOUT'}
                                    {uploading && 'SAVING...'}
                                </button>
                            </div>
                        </form>


                        {/* popup */}
                        <div id="term_popup" className="term_popup inner-page" style={{display: display_term_popup}}>
                            <div className="term_popup_wrap_close"></div>
                            <div className="term_popup__content">
                                <div className="term_popup__content_close" onClick={() => hideTermPopup()}><svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.01001 7.0813C8.3147 7.36255 8.3147 7.85474 8.01001 8.13599C7.86938 8.27661 7.68188 8.34692 7.49438 8.34692C7.28345 8.34692 7.09595 8.27661 6.95532 8.13599L4.49438 5.67505L2.01001 8.13599C1.86938 8.27661 1.68188 8.34692 1.49438 8.34692C1.28345 8.34692 1.09595 8.27661 0.955322 8.13599C0.650635 7.85474 0.650635 7.36255 0.955322 7.0813L3.41626 4.59692L0.955322 2.13599C0.650635 1.85474 0.650635 1.36255 0.955322 1.0813C1.23657 0.776611 1.72876 0.776611 2.01001 1.0813L4.49438 3.54224L6.95532 1.0813C7.23657 0.776611 7.72876 0.776611 8.01001 1.0813C8.3147 1.36255 8.3147 1.85474 8.01001 2.13599L5.54907 4.62036L8.01001 7.0813Z" fill="white"/>
                                    </svg>
                                </div>
                                <div className="term_popup_box">
                                    <div className="herader_txt">
                                        <h3>Content Submission Agreement</h3>
                                    </div>
                                    <div className="content_txt">
                                    {/* <h5>Lorem Ipsum is simply dummy text</h5> */}
                                    <h5>1. Acceptance of Terms:</h5>
                                    By accessing and using our application &quot;ShoutOut&quot; (&quot;the App&quot;), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please refrain from using the App.

                                    <h5>2. Information Collection and Usage:</h5>
                                    We collect certain information from businesses (&quot;Clients&quot;) and their customers (&quot;Customers&quot;) to facilitate the collection of branded video testimonials. This information includes but is not limited to name, contact details, and video content (&quot;Customer Information&quot;). By using the App, Customers acknowledge and consent to the collection and usage of their Customer Information for marketing purposes.

                                    <h5>3. Consent for Video Testimonials:</h5>
                                    By submitting video testimonials through the ShoutOut App, Customers grant the Client and us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, distribute, and display the video content for marketing and promotional purposes on social media platforms, websites, and other marketing materials.

                                    <h5>4. Customer Responsibility:</h5>
                                    Customers are solely responsible for the content of the video testimonials they submit through Shout Out. Video content must not contain offensive, inappropriate, or illegal content. Customers affirm that they have the necessary rights and permissions to grant the license for the video content they submit.

                                    <h5>5. Marketing Usage:</h5>
                                    The video testimonials collected through the ShoutOut App may be shared on social media platforms, Client websites, and other marketing channels. Customers acknowledge that their video testimonials may be publicly available and accessible by a wide audience.

                                    <h5>6. Intellectual Property:</h5>
                                    Clients and Customers retain ownership of their respective intellectual property rights in the video testimonials they create using the ShoutOut App. However, by submitting video testimonials, Customers grant the Client and us the aforementioned license to use the content for marketing purposes.

                                    <h5>7. Data Security and Privacy:</h5>
                                    We take data security and privacy seriously. Customer Information will be handled in accordance with our Privacy Policy, which outlines how we collect, use, and protect personal information. Please review the Privacy Policy for more information.

                                    <h5>8. Indemnification:</h5>
                                    Clients and Customers agree to indemnify and hold us harmless from any claims, damages, liabilities, and expenses arising from the use of video testimonials submitted through the ShoutOut App, including but not limited to claims of copyright infringement, defamation, or violation of rights.

                                    <h5>9. Modifications and Termination:</h5>
                                    We reserve the right to modify or terminate the ShoutOut App or these Terms and Conditions at any time without prior notice. Clients and Customers are advised to review the Terms periodically. Continued use of the ShoutOut App after changes constitutes acceptance of the modified Terms.

                                    <h5>10. Governing Law:</h5>
                                    These Terms and Conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].

                                    <h5>11. Contact Information:</h5>
                                    For questions or concerns regarding these Terms and Conditions or the ShoutOut App, please contact us at [Contact Email/Address].

                                    <p>By using the ShoutOut App and submitting video testimonials, Clients and Customers agree to abide by these Terms and Conditions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
              
                )}

                {uploading && (
                    <>

                    {/* new popup */}
                    {/* <div id="alert_popup" className="alert_popup inner-page" >
                        <div className="alert_popup__content">
                            <div className="alert_popup_box">
                                <div className="alert-icon">
                                    <svg width="92" height="91" viewBox="0 0 92 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.5 0.767578L2 26.7676L0 65.2676L28.5 90.7676H65L92 63.2676V41.7676L75.5 10.7676L63 0.767578H26.5Z" fill="white"/>
                                    </svg>
                                </div>
                                <div className="alert_content_box">
                                    <span>You will Loose it if you leave!</span>
                                    <h4>Wait till the upload is completed.</h4>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* old popup */}
                    <div className='shoutout_progress_wraps'>
                        <span className="wait_emoji">✋</span>
                        <h2>Please Wait {userData.firstName}! </h2>
                        <h3>Don’t close this window yet!</h3>
                        <Lottie options={defaultOptions} height={180} width={180} />
               
                        <h5>We’re uploading your masterpiece ...</h5>
                        {/* <div className='progressBar'>
                            <ProgressBar
                                now={progress}
                                label={`${progress}% completed`}
                            />
                        </div> */}
                    </div>

                    </>


                )}
                <CopyRights />
            </div>

            
        </MainLayout>
    );
};
