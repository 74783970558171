import React, { useState } from 'react';
import { 
    //useDispatch, 
    useSelector 
} from 'react-redux';

import {
    getDesignConfig,
    setExperience,
} from '../features/studio/studioSlice';
import { useNavigate } from 'react-router-dom';
import TwoColumnLayout from '@src/layout/TwoColumnLayout';

export const GetStart = () => {
    //const dispatch = useDispatch();
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    //const [exp, setExp] = useState(null);
    const [exp, setExp] = useState('video'); console.log(setExp); console.log(exp);
    const [agree, setAgree] = useState(false); console.log(setAgree); console.log(agree);
    //
    const shoutout_questions_count = designConfig.shoutout_questions_count;
    const shoutout_questions_total_time = designConfig.shoutout_questions_total_time;
    const shoutout_questions_data = designConfig.shoutout_questions_data;
    //console.log(shoutout_questions_data.length);
    const qtext = (shoutout_questions_count > 1) ? 'questions' : 'question';
    const mtext = (shoutout_questions_total_time > 1) ? 'minutes' : 'minute';
    //
    const show_continue_btn = (shoutout_questions_data.length) ? 'block' : 'none';
    const show_question_notice = (shoutout_questions_data.length) ? 'none' : 'block';
    //
    const navigate = useNavigate();

    const setExperiences = () => {
        //dispatch(setExperience(exp));
        setExperience(exp);
        navigate('/booth');
    };

    const bodyTextColor = designConfig.body_text_color;
    const btnBgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;

    return (

        <TwoColumnLayout loading={false} body_dark_color="#4B04B8">

            <div className='shoutout_getStart_content'>

                <div className="question_box">
                    <div className="question_heading">
                        <h2 style={{ color: bodyTextColor }}>Check out your questions</h2>
                        <h6 style={{ color: bodyTextColor }}>{shoutout_questions_count} {qtext} - {shoutout_questions_total_time} {mtext}</h6>
                    </div>
                    <div className="question_listing">
                        <ul>
                          {shoutout_questions_data.map((item, index) =>
                              <li key={item.id}>
                                <div className="question_listing">
                                    <span>{index+1}</span>
                                    <p style={{ color: bodyTextColor }}>{item.question_text}</p>
                                </div>
                              </li>
                          )}                            
                           
                        </ul> 
                    </div>
                </div>

                {/* <div className='shoutout_exprience_btnGroup'>
                    
                    <div className="shoutout_respond_btn">
                        
                        
                    </div>
                </div> */}

                {/* <div className="shoutout_legal_part">
                    <h3 style={{color: bodyTextColor }}>The necessary legal part...</h3>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"  id="flexCheckChecked"  onChange={() => { setAgree(!agree); setExp('video');} } />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                I agree to the <span>content submission agreement</span>
                        </label>
                    </div>
                </div> */}
                <div className="continue_btn_wrap">
                    <button
                        className='btn btn-primary btn-block'
                        /* disabled={!(agree && exp)} */
                        onClick={() => setExperiences('video')}
                        style={{
                            backgroundColor: btnBgColor,
                            borderColor: btnBgColor,
                            color: btnTextColor,
                            display: show_continue_btn,
                        }}>
                        <span>Continue</span>
                    </button>
                    <p style={{display : show_question_notice}}>Please add Questions!</p>
                </div>     
            </div>
        </TwoColumnLayout>
    );
};
