export const steps = [
  {
    selector: ".messageTextarea",
    content: ({ currentStep, setCurrentStep }) => (
      <div className='tour-item-wrap'>
        <p>Type your ShoutOut here, tell us about your experience.</p>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => setCurrentStep(currentStep + 1)}>
          Next
        </button>
      </div>
    ),
    position: "bottom",
    style: {
      width: "100%",
      maxWidth: "44px",
      minWidth: "320px",
    },
  },
  {
    selector: ".fullNameText",
    content: ({ currentStep, setCurrentStep }) => (
      <div className='tour-item-wrap'>
        <p>Type you name. its required</p>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => setCurrentStep(currentStep - 1)}>
          Prev
        </button>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => setCurrentStep(currentStep + 1)}>
          Next
        </button>
      </div>
    ),
    position: "bottom",
    style: {
      width: "100%",
      maxWidth: "44px",
      minWidth: "320px",
    },
  },
  {
    selector: ".designationText",
    content: ({ setCurrentStep, currentStep, setIsOpen }) => (
      <div className='tour-item-wrap'>
        <p>
          Optional: Type your company name and title if you are creating a
          ShoutOut on behalf of a company. If not, leave it blank.
        </p>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => setCurrentStep(currentStep - 1)}>
          Prev
        </button>
        <button
          className='btn btn-sm btn-success'
          onClick={() => setIsOpen(false)}>
          Close
        </button>
      </div>
    ),
    position: "bottom",
    style: {
      width: "100%",
      maxWidth: "44px",
      minWidth: "320px",
    },
  },
];

export const tourConfig = {
  steps: steps,
  className: "tour-main-wrap",
  showNavigation: false,
  showCloseButton: false,
  disableDotsNavigation: false,
  maskSpace: 0,
  rounded: 12,
  showButtons: false,
  showNumber: false,
  showBadge: false,
  padding: 0,
  styles: {
    popover: (base) => ({
      ...base,
      borderRadius: 10,
    }),
    maskArea: (base) => ({ ...base, rx: 10 }),
    // maskWrapper: (base) => ({ ...base, color: "#ef5a3d" }),
    // badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
    // controls: (base) => ({ ...base, marginTop: 50 }),
    // close: (base) => ({ ...base, right: "auto", left: 8, top: 8 })
  },
};
