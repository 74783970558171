import { createRef } from 'react';
import Lottie from 'react-lottie';
import * as animationData from '@src/assets/lottie/red-play-button.json';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import {
    getDesignConfig,
} from '@src/features/studio/studioSlice';

export const PlayButton = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const childRef = createRef(null);
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    const bgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;
    

    return (
        <>
        {isMobile && props.playing &&

                <div className="welcome_play_btn">
                    <button 
                    onClick={props.onClick}
                    className='btn btn-primary'
                        style={{backgroundColor: bgColor,
                            borderColor: btnTextColor,
                            color: btnTextColor,
                        }}>
                        {/* <svg className="pause_btn" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path style={{fill: btnTextColor}} d="M12.75 0C13.9688 0 15 0.973958 15 2.125V14.875C15 16.0703 13.9688 17 12.75 17L11.25 16.9557C9.98438 16.9557 9 15.9818 9 14.8307V2.08073C9 0.929687 9.98438 0 11.25 0H12.75ZM3.75 0C4.96875 0 6 0.973958 6 2.125V14.875C6 16.0703 4.96875 17 3.75 17H2.25C0.984375 17 0 16.0703 0 14.875V2.125C0 0.929687 0.984375 0 2.25 0H3.75Z" />
                        </svg> */}
                        <svg className="pause_btn" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect style={{fill: btnTextColor}} width="50" height="50" rx="3" fill="#ffffff"/>
                        </svg>
                    </button>
                </div>
        } 

        {isMobile && !props.playing &&

                <button 
                    style={{ background: 'none', border: 'none' }}
                    onClick={props.onClick}>
                    <Lottie
                        options={defaultOptions}
                        height={60}
                        width={180}
                        isStopped={props.playing}
                        onClick={() => childRef.current.playVideoRef()}
                    />
                </button>

        }

        {!isMobile && 
                <button 
                    style={{ background: 'none', border: 'none' }}
                    onClick={props.onClick}>
                    <Lottie
                        options={defaultOptions}
                        height={60}
                        width={180}
                        isStopped={props.playing}
                        onClick={() => childRef.current.playVideoRef()}
                    />
                </button>
        }
        </>
    );
};
