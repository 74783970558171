import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
//import './@fake-db';

//import 'dotenv/config'
//import express from 'express'
//import process from "process";

export const Laravel = {
  invited_user_data: {
    firstName: "",
    lastName: "",
    email: "",
  },
  //siteUrl: "http://stori.localhost/",
  //hub_url: process.env.REACT_APP_HUB_URL,
  //global_mosaic_id: 175,
}

const root = ReactDOM.createRoot(document.getElementById("root")); //console.log("REACT_APP_HUB_URL variable: " + process.env.REACT_APP_HUB_URL); 

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(Laravel);
