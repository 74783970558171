import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fixWebmDuration from 'fix-webm-duration';
import { isMobile } from 'react-device-detect';
import {
    getDesignConfig,
    setImage,
    setStatus,
    getStatus,
    selectExperience,
    setVideoData,
    changeScreen,
    changeCameraFace,
    selectPreviewSize,
    setProfile,
    setExperience,
} from '@src/features/studio/studioSlice';
import gifshot from 'gifshot';
import { VideoTimer, Progress, Counter } from '.';
import { useNavigate } from 'react-router-dom';

const MIME_TYPES = [
    'video/webm;codecs="vp8,opus"',
    'video/webm;codecs=h264',
    'video/webm;codecs=vp9',
    'video/webm',
    'video/mp4',
];

export const Capture = forwardRef((props, ref) => {
    const camra_btn_Vedio =
    require('@src/assets/images/camra_btn_Vedio.svg').default;
    const camra_btn_Upload =
    require('@src/assets/images/camra_btn_Upload.svg').default;

    //console.log(camra_btn_Vedio);

    const status = useSelector(getStatus);
    const designConfig = useSelector(getDesignConfig);
    const experience = useSelector(selectExperience);

    //console.log('experience', experience);

    const previewSize = useSelector(selectPreviewSize);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const isInitialMount = useRef(true);
    const uploadbtnOpacity = (!capturing && status !== 'counter') ? 1 : 0;

    //--------------------------------------------------------
    // getting question data
    const shoutout_questions_count = designConfig.shoutout_questions_count; //console.log('shoutout_questions_count: ' + shoutout_questions_count);
    //const max_question_video_duration = (designConfig.max_question_video_duration > 0) ? designConfig.max_question_video_duration : 20;
    //console.log('max_question_video_duration:' + max_question_video_duration);
    //console.log('designConfig.max_question_video_duration:' + designConfig.max_question_video_duration);

    // getting current questino video duration
    let max_question_video_duration = 59;
    let cur_q_num = '';
    let cur_q = '';
    let cur_q_id = 0;
    let initial_minute = 0;
    let initial_seconds = 59;

    if(shoutout_questions_count > 0){
        max_question_video_duration = localStorage.getItem('max_question_video_duration');
        if (max_question_video_duration === null) {
            max_question_video_duration = (designConfig.shoutout_questions_data[0]['max_question_video_duration']) ? designConfig.shoutout_questions_data[0]['max_question_video_duration'] : 59;
            localStorage.setItem('max_question_video_duration', max_question_video_duration); // its also being set from Desicion page
        }

        // getting current question number
        cur_q_num = localStorage.getItem('cur_q_num');
        if (cur_q_num === null) {
            cur_q_num = 1;
            localStorage.setItem('cur_q_num', cur_q_num); // setting question number // its also being set from Desicion page
        }

        // getting current question text
        cur_q = localStorage.getItem('cur_q');
        if (cur_q === null) {
            cur_q = designConfig.shoutout_questions_data[0]['question_text'];
            localStorage.setItem('cur_q', cur_q); // setting current question // its also being set from Desicion page
        }

        // getting current question id
        cur_q_id = localStorage.getItem('cur_q_id');
        if (cur_q_id === null) {
            cur_q_id = designConfig.shoutout_questions_data[0]['id'];
            localStorage.setItem('cur_q_id', cur_q_id); // setting current question id // its also being set from Desicion page
        }

        // getting current initial_minute
        initial_minute = localStorage.getItem('initial_minute');
        if (initial_minute === null) {
            initial_minute = designConfig.shoutout_questions_data[0]['initial_minute'];
            localStorage.setItem('initial_minute', initial_minute); // setting current initial_minute // its also being set from Desicion page
        }

        // getting current initial_seconds
        initial_seconds = localStorage.getItem('initial_seconds');
        if (initial_seconds === null) {
            initial_seconds = designConfig.shoutout_questions_data[0]['initial_seconds'];
            localStorage.setItem('initial_seconds', initial_seconds); // setting current initial_seconds // its also being set from Desicion page
        }

    }

    initial_minute = parseInt(initial_minute);
    initial_seconds = parseInt(initial_seconds);
    
    //--------------------------------------------------------
    // debug
    //--------------------------------------------------------
   /*  console.log('cur_q_num: ' + cur_q_num);
    console.log('cur_q: ' + cur_q);
    console.log('cur_q_id: ' + cur_q_id);
    console.log('initial_minute: ' + initial_minute);
    console.log('initial_seconds: ' + initial_seconds);
    console.log('max_question_video_duration: ' + max_question_video_duration); */
    //--------------------------------------------------------

    //to fix video duration issue
    let lastRecordingTimestamp = 0;
    let recordingDuration = 0;

    const getMimeType = () => {
        const mimeType = window.MediaRecorder.isTypeSupported
            ? MIME_TYPES.find(window.MediaRecorder.isTypeSupported)
            : 'video/webm';

        return (
            (mediaRecorderRef.current && mediaRecorderRef.current.mimeType) ||
      mimeType ||
      ''
        );
    };

    const getTotalEllapsedTimeInMs = React.useCallback(() => {
        //console.log('getTotalEllapsedTimeInMs');
        //console.log(lastRecordingTimestamp);
        //console.log(recordingDuration);

        return Date.now() - lastRecordingTimestamp + recordingDuration;
    }, [lastRecordingTimestamp, recordingDuration]);

    // see https://bugs.chromium.org/p/chromium/issues/detail?id=642012
    const fixVideoMetadata = (rawVideoBlob, recordingDuration) => {
    // see https://stackoverflow.com/a/63568311
        Blob.prototype.arrayBuffer ??= function () {
            return new Response(this).arrayBuffer();
        };

        return fixWebmDuration(rawVideoBlob, recordingDuration);
    };

    const handleDownload = React.useCallback(() => {
        //console.log('inside handler');
        //console.log('chunks? ', recordedChunks.length);
        let mimeType = getMimeType();
        console.log('mimeType ', mimeType);

        const videoBlob =
      recordedChunks.length === 1
          ? recordedChunks[0]
          : new Blob(recordedChunks, {
              type: mimeType,
          });

        mediaRecorderRef.current.ondataavailable = null;
        fixVideoMetadata(videoBlob, recordingDuration).then((fixedVideoBlob) => {
            dispatch(setVideoData(fixedVideoBlob));
            setTimeout(() => {
                dispatch(changeScreen('decision'));
                navigate('/decision');
                dispatch(setStatus('idle'));
            }, 300);
        });
    }, [recordedChunks]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (recordedChunks.length > 0) {
                //console.log('running handleDownload');
                handleDownload();
            }
        }
    }, [recordedChunks]);

    //stop capture if times Up
    useImperativeHandle(
        ref,
        () => ({
            stopCapture() {
                //console.log('stopCapture');
                //console.log(capturing);
                handleStopCaptureClick();
            },
        }),
        []
    );

    const handleStartCaptureClick = React.useCallback(() => {
        setCapturing(true);
        let mimeType = getMimeType();
        //console.log('mimeType ', mimeType);
        mediaRecorderRef.current = new MediaRecorder(
            props?.webcamRef.current.stream,
            {
                mimeType: mimeType,
            }
        );

        mediaRecorderRef.current.addEventListener(
            'dataavailable',
            handleDataAvailable
        );
        mediaRecorderRef.current.start();

        //setting recording time
        lastRecordingTimestamp = Date.now();
        recordingDuration = 0;

        dispatch(setStatus('videoCapturing'));
    }, [
        props?.webcamRef,
        setCapturing,
        mediaRecorderRef,
        lastRecordingTimestamp,
        recordingDuration,
    ]);

    const handleDataAvailable = React.useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = React.useCallback(() => {
        mediaRecorderRef.current.stop();
        recordingDuration = getTotalEllapsedTimeInMs();

        //console.log('handleStopCaptureClick');
        //console.log(recordingDuration);

        //before duration fix
        setCapturing(false);
        dispatch(setStatus('loading'));
    }, [mediaRecorderRef, props.webcamRef, setCapturing]);

    const createTakeSnap = (video) => {
        setCapturing(true);

        let img = new Image();
        img.src = designConfig.frame;
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            let gifWidth = previewSize.width > previewSize.height ? 538 : 720;
            let gifHeight = previewSize.width > previewSize.height ? 720 : 960;

            gifshot.takeSnapShot(
                {
                    gifWidth: gifWidth,
                    gifHeight: gifHeight,
                    numWorkers: 2,
                    keepCameraOn: true,
                    cameraStream: video,
                    waterMark: img,
                    waterMarkWidth: gifWidth,
                    waterMarkHeight: gifHeight,
                    waterMarkXCoordinate: 0,
                    waterMarkYCoordinate: 0,
                },
                (obj) => {
                    //console.log(obj);
                    if (!obj.error) {
                        let result = obj.image.replace('image/gif', 'image/jpeg');
                        dispatch(setImage(result));
                        setCapturing(false);
                        dispatch(changeScreen('decision'));
                        navigate('/decision');
                    } else {
                        alert(obj.errorMsg);
                    }
                }
            );
        };
        img.onerror = () => {
            console.log('Image not loaded');
        };
    };

    // const wait = (delayInMS) => {
    //     return new Promise((resolve) => setTimeout(resolve, delayInMS));
    // };

    const capture = React.useCallback(() => {
        props.startCounter();
    }, [props.webcamRef, experience, capturing]);

    const startAction = () => {
        dispatch(setStatus('idle'));
        if (experience === 'video') {
            if (!capturing) {
                handleStartCaptureClick();
            }
        } else {
            createTakeSnap(props.webcamRef.current.stream);
        }
    };

    const timesUp = () => {
        if (props.webcamRef.current) {
            //console.log('timesUp');
            handleStopCaptureClick();
        }
    };

    const captureProfile = () => {
        if (props.webcamRef.current) {
            setCapturing(true);
            gifshot.takeSnapShot(
                {
                    gifWidth: 400,
                    gifHeight: 400,
                    numWorkers: 2,
                    keepCameraOn: true,
                    cameraStream: props.webcamRef.current.stream,
                },
                (obj) => {
                    //console.log(obj);
                    if (!obj.error) {
                        let result = obj.image.replace('image/gif', 'image/jpeg');
                        dispatch(setImage(result));
                        dispatch(setProfile(result));
                        setCapturing(false);
                        dispatch(changeScreen('text'));
                        navigate('/text');
                    } else {
                        alert(obj.errorMsg);
                    }
                }
            );
        }
    };

    const onChangeFile = (e) => {
        let file = e.target.files[0];

        if (file.type.includes('video')) {
            dispatch(setExperience('video'));
            dispatch(setStatus('idle'));
            dispatch(setVideoData(file));
            dispatch(changeScreen('decision'));
            navigate('/decision');
        } else {
            let reader = new FileReader();
            reader.onloadend = () => {
                if (experience !== 'text') {
                    dispatch(setExperience('photo'));
                    dispatch(setStatus('idle'));
                    dispatch(setImage(reader.result));
                    dispatch(changeScreen('decision'));
                    navigate('/decision');
                } else {
                    props.openCropPage(reader.result.toString());
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='shoutout_recording_action'>
            <div className='shoutout_recording_action_wrap'>
                {status === 'counter' && <Counter startAction={startAction} />}

                {!capturing && status !== 'counter' && shoutout_questions_count > 0 && (
                <div className='Shoutout_question_txt'>
                    <h3>Question {cur_q_num} of {shoutout_questions_count}</h3>
                    <h5>{cur_q}</h5>
                </div>
                )}


                <div className='shoutout_recording_txt'>
                    {experience !== 'video' && !capturing && status !== 'counter' && (
                        <div className='shoutout_recording_txt_start'>Say Cheeeeese!</div>
                    )}
                    {experience === 'video' && !capturing && status !== 'counter' && (
                        <div className='shoutout_recording_txt_start'>
                            Tap the red button to start!
                        </div>
                    )}
                    {status === 'videoCapturing' && (
                        <VideoTimer initialMinute={initial_minute} initialSeconds={initial_seconds} timeOverEvent={() => timesUp()} />
                    )}
                </div>
                <div className='shoutout_recording_action_btn'>

                    {/* {!capturing && status !== 'counter' && ( */}
                    <div className='shoutout_recording_action_btn_width' style={{opacity:uploadbtnOpacity}}>
                        <div className='shoutout_camra_action_btn camra_btn_Upload'>
                            <label className='btn btn-dark'>
                                <input
                                    type='file'
                                    accept={experience == 'text' ? 'image/*' : 'video/*'}
                                    name='photo'
                                    id='upload-photo'
                                    multiple={false}
                                    onChange={onChangeFile}
                                />
                                <img src={camra_btn_Upload} alt='' />
                            </label>
                        </div>
                    </div>
                    {/* )} */}


                    <div className='shoutout_action_btn_record'>
                        <div className='actionButtonWrap'>
                            {experience === 'video' && capturing && <Progress totalsecs={max_question_video_duration} />}
                            {experience === 'text' && (
                                <button
                                    className='btn btn-danger btn_recording'
                                    onClick={captureProfile}>
                  SNAP
                                </button>
                            )}
                            {experience !== 'video' && experience !== 'text' && (
                                <button
                                    className='btn btn-primary btn_recording'
                                    onClick={capture}>
                  SNAP
                                </button>
                            )}
                            {experience === 'video' && !capturing && (
                                <button
                                    className='btn btn-danger btn_recording'
                                    onClick={capture}></button>
                            )}
                            {experience === 'video' && capturing && (
                                <button
                                    className='btn btn-danger btn_recording'
                                    onClick={handleStopCaptureClick}>
                                    <span></span>
                                </button>
                            )}
                        </div>
                        <div className='video_timer'>
                            <a href='' className='btn btn-danger btn_recording_stop'></a>
                            <div className='video_overlay'></div>
                            <div className='video_left'></div>
                            <div className='video_right'></div>
                        </div>
                    </div>
                    <div className='shoutout_recording_action_btn_width'>
                        <div className='shoutout_action_btn_switch' style={{display:'none'}}>
                            {isMobile && (
                                <button
                                    className='btn btn-dark'
                                    onClick={() => dispatch(changeCameraFace())}>
                                    <img src={camra_btn_Vedio} alt='' />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
