import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeScreen,
    getDesignConfig,
    //selectFrame,
    setDecision,
    //setImage,
    selectImage,
    selectCapturedVideo,
    selectPreviewSize,
    selectExperience,
    //setVideoData,
    //setRecordData,



} from '../features/studio/studioSlice';
import { useNavigate } from 'react-router-dom';
import MainLayout from '@src/layout/MainLayout';
import {
    isMobile,
    browserName,
    isMobileOnly,
    isTablet,
    isDesktop,
    isAndroid,
    isWinPhone,
    isIOS,
    isMobileSafari,
    isSamsungBrowser,
    osVersion,
    osName,
    mobileVendor,
    mobileModel,
    deviceType,
    isIOS13,
    isIPhone13,
    isWindows,
    isMacOs,
} from 'react-device-detect';

//-------------------
import Resumable from "resumablejs";
import { ProgressBar } from 'react-bootstrap';
import Lottie from "react-lottie";
import * as animationData from "../assets/lottie/7877-uploading-to-cloud.json";
import * as animationData2 from "../assets/lottie/7878-decision-stop-while-uploading.json";

//import axios from "axios";
//---------------------

export const Decision = () => {

    const storiurl = process.env.REACT_APP_HUB_URL;

    const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		/* rendererSettings: {
		  preserveAspectRatio: "xMidYMid slice"
		} */
    }
    const defaultOptions2 = {
		loop: true,
		autoplay: true,
		animationData: animationData2,
		/* rendererSettings: {
		  preserveAspectRatio: "xMidYMid slice"
		} */
    }

    const videoRef = useRef(null);
    const buttonRef = useRef(null);
    const frameRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const capturedImage = useSelector(selectImage); //console.log('capturedImage: ' + capturedImage);
    const previewSize = useSelector(selectPreviewSize); //console.log('previewSize: ' + previewSize);
    const videoData = useSelector(selectCapturedVideo); //console.log('videoData: ' + videoData);
    const experience = useSelector(selectExperience); //console.log('experience: ' + experience);
    const [processing, setProcessing] = useState(false);
    const [hide_lup, hide_looseUploadPopup] = useState(false);
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    //const frame = useSelector(selectFrame); console.log('frame: ' + frame);
    //const frame = designConfig.frame; //useSelector(selectFrame); console.log('frame: ' + frame);
    const frame = (isMobile) ? designConfig.frame2 : designConfig.frame; //console.log('isMobile: ' + isMobile);  console.log('frame: ' + frame);
    const mosaic_id = designConfig.mosaic_id;
    const cur_q_id = localStorage.getItem('cur_q_id');

    let device_details = [];
    device_details.push({
        "isMobile": isMobile,
        "isMobileOnly": isMobileOnly,
        "isTablet": isTablet,
        "isDesktop": isDesktop,
        "isAndroid": isAndroid,
        "isWinPhone": isWinPhone,
        "isIOS": isIOS,
        "browserName": browserName,
        "isMobileSafari": isMobileSafari,
        "isSamsungBrowser": isSamsungBrowser,
        "osVersion": osVersion,
        "osName": osName,
        "mobileVendor": mobileVendor,
        "mobileModel": mobileModel,
        "deviceType": deviceType,
        "isIOS13": isIOS13,
        "isIPhone13": isIPhone13,
        "isWindows": isWindows,
        "isMacOs": isMacOs,
    });
   
    console.log(device_details);
    
    //console.log('isMobile: ' + isMobile);
    //console.log('isMobileOnly: ' + isMobileOnly);
    //console.log('isTablet: ' + isTablet);
    //console.log('isDesktop: ' + isDesktop);
    //console.log('isAndroid: ' + isAndroid);
    //console.log('isWinPhone: ' + isWinPhone);
    //console.log('isIOS: ' + isIOS);
    //console.log('browserName: ' + browserName);
    //console.log('isMobileSafari: ' + isMobileSafari);
    //console.log('isSamsungBrowser: ' + isSamsungBrowser);
    //console.log('osVersion: ' + osVersion);
    //console.log('osName: ' + osName);
    //console.log('mobileVendor: ' + mobileVendor);
    //console.log('mobileModel: ' + mobileModel);
    //console.log('deviceType: ' + deviceType);
    //console.log('isIOS13: ' + isIOS13);
    //console.log('isIPhone13: ' + isIPhone13);
    //console.log('isWindows: ' + isWindows);
    //console.log('isMacOs: ' + isMacOs);
    //console.log('deviceType: ' + deviceType);
    //-----------------------------------

    /* const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
            'Content-type' :'application/json'
		},
		onUploadProgress: function (progressEvent) {
			let percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total
			);
			setProgress(percentCompleted);
			console.log(percentCompleted);
		},
	}; */

    //--------------------------------
    //2 mb chunk size
	const chunkSize = 1048576 * 1;
    const [uploading, setUploading] = useState(false); console.log(uploading);
    const videos = useSelector(selectCapturedVideo);
    const [progress, setProgress] = useState(0);
    const uploading_class_shoutout_content_section = (uploading) ? 'uploading_process' : '';
    //--------------------------------------------
    let playTimeout;

    const playVideo = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
                buttonRef.current.classList.add('active');
            } else {
                videoRef.current.pause();
                buttonRef.current.classList.remove('active');
            }
        }
    };

    const videoEnded = () => {
        if (buttonRef.current) {
            buttonRef.current.classList.remove('active');
            //buttonRef.current.style.opacity = 1;
            buttonRef.current.style.backgroundColor = 'transparent';
        }
    };

    const videoPlay = () => {
        if (buttonRef.current) {
            buttonRef.current.classList.add('active');
            playTimeout = setTimeout(() => {
                if (buttonRef.current) {
                    //buttonRef.current.style.opacity = 0;
                    buttonRef.current.style.backgroundColor = 'transparent';
                }
            }, 1000);
        }
    };

    const tapOnVideo = () => {
        if (buttonRef.current && !videoRef.current.paused) {
            //buttonRef.current.style.opacity = 1;
            buttonRef.current.style.backgroundColor = 'transparent';
        }
    };

    //---------------------------------------------------------------------------

    const pauseVideoWhileUploading = () => {
        // pausing video while uploading
        console.log('videoRef.current.paused');
        console.log(!videoRef.current.paused);
        console.log('buttonRef');
        console.log(buttonRef);

        if (buttonRef.current && videoRef.current) {
            videoRef.current.pause();
            buttonRef.current.classList.remove('active');
            console.log('paused video while uploading');
        }
    };

    const uploadMedia = () => {

		if (experience == 'video') {

			let videoFile = new File([videos], 'video-name.webm', { type: 'video/webm' });
			// formData.append('media', videoFile);

            const formData = new FormData();
            formData.append('experience', experience);
            formData.append('social_text', '');
            formData.append('mosaic_id', mosaic_id);
            formData.append('cur_q_id', cur_q_id);
            formData.append('file', videoFile);

            console.log(chunkSize);
            console.log('videoFile');
            console.log(videoFile);

            // pausing video while uploading
            pauseVideoWhileUploading();

            //---------------------------------------------------------
            // full upload
            //---------------------------------------------------------

            /* axios
            .post(`${storiurl}/api/shoutout`, formData, config)
            .then((response) => {
                console.log(response);
                let res = response.data;

                if (res.success) {
					setUploading(false);
                    // setting question data to storage
                    if(res.cur_q_id > 0){
                        localStorage.setItem('max_question_video_duration', res.max_question_video_duration);
                        localStorage.setItem('cur_q_num', res.cur_q_num); // setting question number
                        localStorage.setItem('cur_q', res.cur_q); // setting current question
                        localStorage.setItem('cur_q_id', res.cur_q_id); // setting current question id
                        //
                        localStorage.setItem('initial_minute', res.initial_minute); // setting initial_minute
                        localStorage.setItem('initial_seconds', res.initial_seconds); // setting initial_seconds
                        // setting response ids
                        let shoutout_question_responses_ids = localStorage.getItem('shoutout_question_responses_ids');
                        if(shoutout_question_responses_ids === null){
                            localStorage.setItem('shoutout_question_responses_ids', res.response_id);
                        } else {
                            let new_ids = shoutout_question_responses_ids +', '+ res.response_id;
                            localStorage.setItem('shoutout_question_responses_ids', new_ids);
                        }

                        //videoFile = null;

                        // new update
                        dispatch(changeScreen('booth'));
                        navigate('/booth');
                    } else {
                        // setting response ids
                        let shoutout_question_responses_ids = localStorage.getItem('shoutout_question_responses_ids');
                        if(shoutout_question_responses_ids === null){
                            localStorage.setItem('shoutout_question_responses_ids', res.response_id);
                        } else {
                            let new_ids = shoutout_question_responses_ids +', '+ res.response_id;
                            localStorage.setItem('shoutout_question_responses_ids', new_ids);
                        }

                        // dont delete storage vars here/ delete them on Legal page when all data saved
                        //
                        dispatch(changeScreen('legal'));
                        navigate('/legal');
                    }
                } // if ends
                else
                {
                    alert(res.message);
                }
                
            }); */


            /* .catch((err) => {
                setUploading(false);
                console.log(err);
                alert('File Upload Error');
            }); */

            //---------------------------------------------------------
			//chunk upload
            //---------------------------------------------------------

			const r = new Resumable({
				target: `${storiurl}/api/shoutout`,
				testChunks: false,
				chunkSize: chunkSize,
				simultaneousUploads: 2,
				query: {
                    social_text: '',
					experience: experience,
                    mosaic_id: mosaic_id,
                    cur_q_id : cur_q_id,
                    device_details : (device_details.length) ? JSON.stringify(device_details) : '',
				},
			});
			r.addFile(videoFile);
			r.on("fileAdded", function (file, event) {
                console.log('event-file-upload');
                console.log(event);
                console.log(file);
				r.upload();
                //pauseVideoWhileUploading();
			});
			r.on("fileSuccess", function (file, response) {
				console.log("sucessfully file uploaded");
				console.log(file);
				console.log(response);

				let res = JSON.parse(response);
                console.log(res);

				if (res.success){
					//dispatch(setVideoData(res.record.social_video_url));
					//dispatch(setRecordData(res.record));
					setUploading(false);
                    //pauseVideoWhileUploading();
					//dispatch(changeScreen('thanks'));

                    // setting question data to storage
                    if(res.cur_q_id > 0){
                        localStorage.setItem('max_question_video_duration', res.max_question_video_duration);
                        localStorage.setItem('cur_q_num', res.cur_q_num); // setting question number
                        localStorage.setItem('cur_q', res.cur_q); // setting current question
                        localStorage.setItem('cur_q_id', res.cur_q_id); // setting current question id
                        //
                        localStorage.setItem('initial_minute', res.initial_minute); // setting initial_minute
                        localStorage.setItem('initial_seconds', res.initial_seconds); // setting initial_seconds
                        // setting response ids
                        let shoutout_question_responses_ids = localStorage.getItem('shoutout_question_responses_ids');
                        if(shoutout_question_responses_ids === null){
                            localStorage.setItem('shoutout_question_responses_ids', res.response_id);
                        } else {
                            let new_ids = shoutout_question_responses_ids +', '+ res.response_id;
                            localStorage.setItem('shoutout_question_responses_ids', new_ids);
                        }

                        //videoFile = null;

                        videoRef.current.play();
                        buttonRef.current.classList.add('active');

                        // new update
                        navigate('/booth');
                    }
                    else {

                        // setting response ids
                        let shoutout_question_responses_ids = localStorage.getItem('shoutout_question_responses_ids');
                        if(shoutout_question_responses_ids === null){
                            localStorage.setItem('shoutout_question_responses_ids', res.response_id);
                        } else {
                            let new_ids = shoutout_question_responses_ids +', '+ res.response_id;
                            localStorage.setItem('shoutout_question_responses_ids', new_ids);
                        }

                        // dont delete storage vars here/ delete them on Legal page when all data saved
                        navigate('/legal');
                    }
				}
                else{
                    alert(res.errors);
                    navigate('/booth');

                }
                
			});
            r.on("fileError", function (file, message) {
                alert("Error Uploading the file, Please try again.");
				console.log("error Uploading the file");
                console.log(file);
                console.log(message);
				setUploading(false);
                //pauseVideoWhileUploading();
			});
			r.on("fileProgress", function (file, message) {
                console.log(message);
				console.log(file.progress());
				// r.progress();
				// file.progress();

				let percentCompleted = Math.round(
					(file.progress() * 100) / 1
				);
				setProgress(percentCompleted);
                //pauseVideoWhileUploading();

			});

		}

	}


	async function handleUpload() {

		setUploading(true);

		if (experience == 'video') {	
			uploadMedia();
		} else {
			uploadMedia();
		}

	}

    //---------------------------------------------------------------------------

    const yes = () => {
        //alert(experience);
        if (experience === 'video') {
            /* setProcessing(false);
            dispatch(changeScreen('legal'));
            navigate('/legal'); */

            // Flow after ajax call
            /* // these values will come from api
            // setting next question data in cache
            localStorage.setItem('cur_q_num', 2);
            localStorage.setItem('cur_q', 'This is question 2');
            dispatch(changeScreen('booth'));
            navigate('/booth'); */

            // pausing video while uploading
            //pauseVideoWhileUploading();

            setProcessing(true);
            handleUpload();


            /* let image = new Image(); console.log(image);
            image.crossOrigin = 'Anonymous';
            image.src = frame; */

            //console.log('start addEventListener');

            /* image.addEventListener('load', function () {

                //alert('in image.addEventListener');
                //console.log(image);
                //console.log(22);

                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);

                try {
                    let img = canvas.toDataURL('image/png');
                    dispatch(setImage(img));
                    //setProcessing(false);
                    //dispatch(changeScreen('legal'));
                    
                    //alert('ok in try');

                    //---------------------
                    handleUpload();

                } catch (err) {
                    alert('something wrong')
                    console.error(err)
                }

            }); */
            
        } else {
            dispatch(changeScreen('/booth'));
            navigate('/booth');
        }
    };

    useEffect(() => {
        let buttonReference = buttonRef.current; //console.log(buttonReference);
        let videoReference = videoRef.current; //console.log(videoReference);

        if (frame && videoData && experience === 'video') {
            frameRef.current.setAttribute('src', frame);
        }

        if (videoData && videoReference) {
            videoReference.play();
        }

        return () => {
            if (videoData && videoReference) {
                //alert(11);

                if (!videoReference.paused) {
                    videoReference.pause();
                    videoReference.currentTime = 0;
                }

                videoReference.src = ''; // empty source
                videoReference.load();
                buttonReference.remove();
            }

            clearTimeout(playTimeout);
        };
    }, []);

    const hideLooseUploadPopup = () => {
        hide_looseUploadPopup(true);
    };

    // let createURLObject = () => {
    //   let URLObject = URL.createObjectURL(videoData);
    //   return URLObject ? URLObject : URLObject;
    // };

    return (
        <MainLayout uploadingClass={uploading_class_shoutout_content_section}>
            <div className='shoutout_video_preview'>
                <div
                    className={`shoutout_decision_wrapper ${
                        experience === 'text' ? 'text_option' : ''
                    }`}
                    onClick={() => tapOnVideo()}>
                    {experience === 'text' && capturedImage && (
                        <img
                            className='media'
                            src={`${capturedImage}`}
                            alt='exp'
                            style={{ maxHeight: 'auto', maxWidth: '100%', width: '100%' }}
                        />
                    )}

                    {experience === 'video' && videoData  &&  (
                        <>
                            <img ref={frameRef} className='frame' alt='' />
                            <video
                                id='video1'
                                ref={videoRef}
                                onEnded={(e) => videoEnded(e)}
                                onPlay={(e) => videoPlay(e)}
                                className='media'
                                src={URL.createObjectURL(videoData)}
                                height={previewSize.height}
                                width={previewSize.width}
                                controls={false}
                                playsInline></video>

                            <button
                                id='playButton'
                                ref={buttonRef}
                                className={'play_btn'}
                                data-click='0'
                                onClick={(e) => playVideo(e)}>
                                <span className='play_btn_arrow'></span>
                            </button>
                        </>
                    )}
                </div>
                <div className='shoutout_look_good_action'>
                    <h1 className='h2'>Look good to you?</h1>
                    <div className='shoutout_look_good_action_btnGroup'>
                        <div className='shoutout_look_good_action_btn'>
                            <button
                                className='btn btn-danger'
                                onClick={() => { dispatch(setDecision('retake')); navigate('/booth');} }
                                disabled={processing}>
                                <span>👎</span>
                                <small>Nope</small>
                            </button>
                        </div>
                        <div className='shoutout_look_good_action_btn'>
                            <button
                                className='btn btn-success'
                                onClick={() => yes()}
                                disabled={processing}>
                                <span>👍</span>
                                <small>Yup!</small>
                            </button>
                        </div>
                    </div>
                </div>
                
                {/* uplaoding file  */}
                {uploading &&

                    <>

                    <div id="uploading_popup" className="uploading_popup inner-page" >
                        {/* <div className="uploading_popup_wrap_close"></div> */}
                        <div className="uploading_popup__content">
                            {/* <div className="uploading_popup__content_close"><svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.01001 7.0813C8.3147 7.36255 8.3147 7.85474 8.01001 8.13599C7.86938 8.27661 7.68188 8.34692 7.49438 8.34692C7.28345 8.34692 7.09595 8.27661 6.95532 8.13599L4.49438 5.67505L2.01001 8.13599C1.86938 8.27661 1.68188 8.34692 1.49438 8.34692C1.28345 8.34692 1.09595 8.27661 0.955322 8.13599C0.650635 7.85474 0.650635 7.36255 0.955322 7.0813L3.41626 4.59692L0.955322 2.13599C0.650635 1.85474 0.650635 1.36255 0.955322 1.0813C1.23657 0.776611 1.72876 0.776611 2.01001 1.0813L4.49438 3.54224L6.95532 1.0813C7.23657 0.776611 7.72876 0.776611 8.01001 1.0813C8.3147 1.36255 8.3147 1.85474 8.01001 2.13599L5.54907 4.62036L8.01001 7.0813Z" fill="white"/>
                                </svg>
                            </div> */}
                            <div className="uploading_popup_box">
                                <div className="header_txt">
                                    <h5>
                                        DON&apos;T CLOSE THIS WINDOW YET
                                    </h5>
                                </div>
                                <div className="progress_wraps popup_content_wrap">	
                                    <Lottie 
                                        options={defaultOptions} 
                                        height={180} 
                                        width={180}
                                    />
                                    <h5>We’re uploading your masterpiece ...</h5>
                                    <div className="progressBar">
                                        <ProgressBar now={progress} label={`${progress}% completed`} />
                                    </div>
                                    <span>Almost done...</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!hide_lup &&
                        <div id="alert_popup" className="alert_popup inner-page" >
                            {/* <div className="alert_popup_wrap_close"></div> */}
                            <div className="alert_popup__content">
                                {/* <div className="alert_popup__content_close"><svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.01001 7.0813C8.3147 7.36255 8.3147 7.85474 8.01001 8.13599C7.86938 8.27661 7.68188 8.34692 7.49438 8.34692C7.28345 8.34692 7.09595 8.27661 6.95532 8.13599L4.49438 5.67505L2.01001 8.13599C1.86938 8.27661 1.68188 8.34692 1.49438 8.34692C1.28345 8.34692 1.09595 8.27661 0.955322 8.13599C0.650635 7.85474 0.650635 7.36255 0.955322 7.0813L3.41626 4.59692L0.955322 2.13599C0.650635 1.85474 0.650635 1.36255 0.955322 1.0813C1.23657 0.776611 1.72876 0.776611 2.01001 1.0813L4.49438 3.54224L6.95532 1.0813C7.23657 0.776611 7.72876 0.776611 8.01001 1.0813C8.3147 1.36255 8.3147 1.85474 8.01001 2.13599L5.54907 4.62036L8.01001 7.0813Z" fill="white"/>
                                    </svg>
                                </div> */}
                                <div className="alert_popup_box">
                                    <div className="alert-icon">
                                    <Lottie 
                                            options={defaultOptions2} 
                                            height={180} 
                                            width={180}
                                        />
                                        {/* <svg width="92" height="91" viewBox="0 0 92 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M26.5 0.767578L2 26.7676L0 65.2676L28.5 90.7676H65L92 63.2676V41.7676L75.5 10.7676L63 0.767578H26.5Z" fill="white"/>
                                        </svg> */}
                                    </div>
                                    <div className="alert_content_box">
                                        <span>You will Loose it if you leave!</span>
                                        <h4>Wait till the upload is completed.</h4>
                                        <a href="#" onClick={() => hideLooseUploadPopup()} className='alert_btn'>Ok I Got it</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }



                    {/* <div className="shoutout_progress_wraps">	
                        <Lottie 
                            options={defaultOptions} 
                            height={180} 
                            width={180}
                        />
                        <h3>Hang tight! </h3>
                        <h5>We’re uploading your masterpiece Shoutout…</h5>
                        <p>Please don’t close your browser window yet!</p>
                        <div className="progressBar">
                            <ProgressBar now={progress} label={`${progress}% completed`} />
                        </div>
                    </div> */}

                    </>
                    
                }

            </div>

            
        </MainLayout>
    );
};
