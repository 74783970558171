import React from 'react';
import { useSelector } from 'react-redux';
import { getDesignConfig } from '../features/studio/studioSlice';

export const MainLogo = (props) => {
    const designConfig = useSelector(getDesignConfig);
    const maxWidthStyle = (props.hideMaxWidth) ? {maxWidth: '100%'} : {maxWidth: '100px'}; //console.log(maxWidthStyle);
    //console.log(props.hideMaxWidth);

    return (
        <div className='shoutout_header_logo'>
            <div className='shoutout_logo_dark'>
                {/*<img src="./images/branding/logo-black.svg" alt="Stori Logo" style={{'maxWidth' : '150px'}} />*/}
                <img
                    src={designConfig.logo}
                    alt='Stori Logo'
                    style={maxWidthStyle}
                />
            </div>
            <div className='shoutout_logo_light'>
                {/*<img src="./images/branding/stori_logo.png" alt="Stori Logo" style={{'maxWidth' : '150px'}} />*/}
                <img
                    src={designConfig.logo}
                    alt='Stori Logo'
                    style={maxWidthStyle}
                />
            </div>
        </div>
    );
};
