import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    changeScreen,
    getDesignConfig
} from '../features/studio/studioSlice';

export const TextPreview = () => {
    const dispatch = useDispatch();
    const designConfig = useSelector(getDesignConfig);
    const bodyBgColor = designConfig.body_bg_color;
    const navigate = useNavigate();

    const btnStyle = {
        backgroundColor: designConfig.btn_bg_color,
        borderColor: designConfig.btn_bg_color,
        color: designConfig.btn_text_color,
    };
    return (
        <div className={'shoutout_text_wrap'} style={{backgroundColor: bodyBgColor,}}>
            <div className="back_btn">
                <button
                    className='btn btn-dark note_btn_back'
                    onClick={() => {
                        dispatch(changeScreen('welcome'));
                        navigate('/welcome');
                    }}>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.60993 0.380558C7.82181 0.592436 7.94894 0.846691 7.94894 1.10095C7.94894 1.39758 7.82181 1.65183 7.60993 1.82133L3.58424 5.84703H20.8312C21.382 5.84703 21.8482 6.31316 21.8482 6.86405C21.8482 7.45731 21.382 7.88106 20.8312 7.88106H3.58424L7.60993 11.9491C8.03369 12.3305 8.03369 12.9662 7.60993 13.3475C7.22855 13.7713 6.59292 13.7713 6.21153 13.3475L0.448433 7.58443C0.0246759 7.20305 0.0246759 6.56742 0.448433 6.18603L6.21153 0.422934C6.59292 -0.000823677 7.22855 -0.000823975 7.60993 0.380558Z" fill="white"/>
                    </svg>
                </button>
            </div>
            <div className="shoutout_answer_box">
                <div className="shoutout_text_heading">
                    <h2>Question 1 of 3</h2>
                    <h3>Tell us why you selected our company for payment processing?</h3>
                </div>
                <div className="shoutout_answer_txt">
                    <h3>Answer</h3>
                    <p>I think you should watch Fassel&apos;s videos because he&apos;s showing us the best restaurants in town and everything he says. It&apos;s great. I recommend watching his videos follow him now.</p>
                    <p>I think you can improve your performance by creating more videos.</p>
                </div>
                <button
                    className='btn btn-primary btn-block'
                    onClick={() => navigate('/test')}
                    style={btnStyle}>
                    <span>Continue</span>
                </button>
            </div>
        </div>
    );
};