import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStatus, selectCounter } from '../features/studio/studioSlice';

export const Counter = ({startAction}) => {
    const counterInit = useSelector(selectCounter);
    let remain = counterInit;
    const [counter, setCounter] = useState(remain);
    const dispatch = useDispatch();
    // const isInitialMount = React.useRef(true);

    const ct = Promise.resolve();

    const countdown = (ct) =>
        Promise.all([
            new Promise((resolve) => {
                setTimeout(() => {
                    remain--;
                    setCounter(remain);
                    resolve(remain);
                    if (remain > 0) {
                        ct.then(countdown(ct));
                    } else {
                        startAction();
                        // setCounter(0);
                    }
                }, 1000);
            }),
        ]);

    const startCountdown = () => {
        dispatch(setStatus('counter'));
        countdown(ct);
    };

    useEffect(() => {
    // if (isInitialMount.current) {
    //     isInitialMount.current = false;
    // } else {
        startCountdown();
    // if (counter == 0) {
    // 		console.log('counter ended');
    // 		dispatch(setStatus('idle'));
    // }
    // }
    }, []);

    return (
        <div className='shoutout_camra_start_counter'>
            <div className='count_num' id='count_num'>
                {counter}
            </div>
        </div>
    );
};
