import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeScreen,
    setUser,
    getDesignConfig,
} from '@src/features/studio/studioSlice';
import {
    PlayButton,
} from '@src/components';

import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Laravel } from '..';
import { useForm } from 'react-hook-form';
import TwoColumnLayout from '@src/layout/TwoColumnLayout';

const UserDetail = () => {

    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const designConfig = useSelector(getDesignConfig);
    const bodyTextWhiteColor = designConfig.body_text_white_color;
    const bgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;
  
    const childRef = useRef();
    const [playing] = useState(false);
    const [loading] = useState(false);

    const navigate = useNavigate();

    const onSubmit = (data) => {
  
        console.log(data);
        let { firstName, lastName } = splitName(data.fullName);
        dispatch(
            setUser({
                firstName,
                lastName,
                email: data.email,
                mosaic_id: Laravel.global_mosaic_id,
            })
        );
        dispatch(changeScreen('welcome'));
        navigate('/welcome');
    };

    function splitName(fullname) {
        let spaceIndex = fullname.indexOf(' ');
        let firstName;
        let lastName;

        if (spaceIndex == -1) {
            firstName = fullname;
            lastName = '';
        } else {
            firstName = fullname.substring(0, spaceIndex);
            lastName = fullname.substr(spaceIndex + 1);
        }

        return {
            firstName,
            lastName,
        };
    }

    return (
        <TwoColumnLayout loading={loading}>
            <div className='shoutout_invitation_form'>
                {isMobile && (
                    <div style={{ marginBottom: 10 }}>
                        <PlayButton
                            playing={playing}
                            onClick={() => childRef.current.playVideoRef()}
                        />
                    </div>
                )}

                <h1
                    className='h3'
                    style={{ color: bodyTextWhiteColor }}>
                  We’re glad you’re here!
                </h1>
                <h4
                    className='h5'
                    style={{ color: bodyTextWhiteColor }}>
                  Ready to give a Shoutout?
                </h4>
                <p style={{ color: bodyTextWhiteColor }}>
                  Enter your info to begin.
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-group'>
                        <input
                            className={
                                errors.fullName
                                    ? 'form-control input-danger'
                                    : 'form-control'
                            }
                            placeholder='Full Name'
                            defaultValue='Jaffer Sunny'
                            {...register('fullName', {
                                required: true,
                                maxLength: 30,
                            })}
                        />
                        {errors.fullName && (
                            <p className='danger'>Please enter full Name</p>
                        )}
                    </div>

                    <div className='form-group'>
                        <input
                            className={
                                errors.email
                                    ? 'form-control input-danger'
                                    : 'form-control'
                            }
                            type='email'
                            placeholder='Email Address'
                            defaultValue='jaffer.sunny@gmail.com'
                            {...register('email', {
                                required: true
                            })}
                        />
                        {errors.email && (
                            <p className='danger'>Please enter correct email.</p>
                        )}
                    </div>
                    <div className='form-group'>
                        <button
                            type='submit'
                            className='btn btn-primary btn-lg btn-block'
                            style={{
                                backgroundColor: bgColor,
                                borderColor: bgColor,
                                color: btnTextColor,
                            }}>
                      I’m ready, let’s go!
                        </button>
                    </div>
                </form>
            </div>
        </TwoColumnLayout>
    );
};

export default UserDetail;
