import React, { useEffect, useState, useRef } from 'react';
import {
    CopyRights,
    CameraActions,
    Capture,
    Loader,
    CameraPermissionError,
} from '@src/components';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
    getStatus,
    setStatus,
    selectVideoConstraints,
    setViewport,
    setPreviewSize,
    selectExperience,
    setImage,
    changeScreen,
    getDesignConfig
} from '@src/features/studio/studioSlice';
import Webcam from 'react-webcam';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';

import placeholder_img  from '@src/assets/images/camera-placeholder-img.svg';

const Booth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //timer ref
    // const captureRef = useRef();

    //camera variables
    const webcamRef = useRef(null);
    const [camPermission, setCamPermission] = useState('');

    // const screen = useSelector(selectScreen);
    const status = useSelector(getStatus);
    const experience = useSelector(selectExperience);

    const videoConstraints = useSelector(selectVideoConstraints);
    const { height, width } = useWindowDimensions();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    const frame = (isMobile) ? designConfig.frame2 : designConfig.frame;

    useEffect(() => {
        dispatch(setViewport({ height, width }));
        dispatch(setStatus('loading'));

        /* iOS re-orientation fix */
        if (
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i)
        ) {
            /* iOS hides Safari address bar */
            window.addEventListener('load', function () {
                setTimeout(function () {
                    window.scrollTo(0, 1);
                }, 1000);
            });
        }
    }, []);

    const cameraLoaded = () => {
        if (webcamRef.current) {
            let streamData = webcamRef.current.stream
                .getVideoTracks()[0]
                .getSettings();
            if (isMobile && isSafari && streamData.width > streamData.height) {
                dispatch(
                    setPreviewSize({
                        width: streamData.height,
                        height: streamData.width,
                    })
                );
            } else {
                let dimes = {
                    width: streamData.width,
                    height: streamData.height,
                };
                dispatch(setPreviewSize(dimes));
            }
        }

        //mute video element on attach
        var videos = document.getElementsByTagName('video');
        if (videos.length) {
            videos[0].muted = true;
        }

        setTimeout(() => {
            dispatch(setStatus('idle'));
        }, 1500);
    };

    const openCropPage = (base64Data) => {
        dispatch(setImage(base64Data));
        dispatch(changeScreen('crop'));
        navigate('/crop');
    };

    const cameraLoadError = () => {
        navigator.permissions
            .query({ name: 'camera' })
            .then((permissionObj) => {
                setCamPermission(permissionObj.state);
                dispatch(setStatus('idle'));
            })
            .catch((error) => {
                console.log('Got error :', error);
            });
    };

    const frameFilter = frame;
    /* experience === 'video'
        ? require('@src/assets/images/video_option_camera_placeholder.png')
        : require('@src/assets/images/text_option_camera_placeholder.jpg'); */

    return (
        <div
            className={`shoutout_wrap ${
                isMobile && isSafari ? 'device_iphone' : ' '
            } ${experience}`}>
            <div className='shoutout_content shoutout_exprience_camra'>
                <div className='shoutout_content_section'>
                    <div className='shoutout_exprience_camra_content'>
                        <div className='shoutout_camra'>
                            <div className='shoutout_camra_wraps'>
                                <div className="shoutout_camera_placeholder">
                                    <img src={placeholder_img} alt='frameFilter' />
                                </div>
                                <span className='shoutout_camra_frame'>
                                    <img className='frame' src={frameFilter} alt='frameFilter' />
                                </span>
                                <Webcam
                                    audio={experience === 'video' ? true : false}
                                    ref={webcamRef}
                                    mirrored={false}
                                    screenshotFormat='image/jpeg'
                                    videoConstraints={videoConstraints}
                                    imageSmoothing={true}
                                    onUserMedia={() => cameraLoaded()}
                                    onUserMediaError={(err) => cameraLoadError(err)}
                                    forceScreenshotSourceSize={false}
                                    style={{
                                        objectFit: isMobile ? 'cover' : 'contain',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                        </div>
                        <CameraActions />
                        <Capture
                            openCropPage={openCropPage}
                            // ref={captureRef}
                            webcamRef={webcamRef}
                            startCounter={() => dispatch(setStatus('counter'))}
                            isSafari={isSafari}
                        />
                    </div>
                    <CopyRights />
                </div>
                {status === 'loading' && <Loader loading={true} />}
                {camPermission === 'denied' && <CameraPermissionError />}
            </div>
        </div>
    );
};

export default Booth;
