import { useLayoutEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Studio } from "./Studio";
import { BrowserRouter as Router } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Loader } from "./components";

import "./app.scss";

import axios from "axios";
import { setUser } from "./features/studio/studioSlice";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [basepath, setBasepath] = useState(true);

  //alert('11');

  useLayoutEffect(() => {

    // IOS Scroll Height
    const appHeight = () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      // by faisal for windows width
      //let vw = window.innerWidth * 0.01;
      //document.documentElement.style.setProperty('--vw', `${vw}px`);

    }
    window.addEventListener('resize', appHeight)
    appHeight()


    const pathname = document.location.pathname;
    const pathInfo = pathname.replace(/^\/+/, "").split("/");
    const storiurl = process.env.REACT_APP_HUB_URL;
    //alert(pathInfo[0]);
    //alert(process.env.REACT_APP_HUB_URL);

    //get invitation detail using invitation id
    if (pathInfo.length > 1 && pathInfo[0] != '') {
      setBasepath(pathInfo[0]);
      axios
        .get(`${storiurl}/getShoutoutNewDesign/${pathInfo[0]}`)
        .then((res) => {
          setLoading(false);
          const response = res.data;
          console.log('from App.js');

          var user = {
            firstName: '',
            lastName: '',
            email: '',
            mosaic_id: '',
          };

          if(typeof response.invited_user_data != 'undefined')
          {
            user = {
              firstName: (typeof response.invited_user_data.firstName != 'undefined') ? response.invited_user_data.firstName : '',
              lastName: (typeof response.invited_user_data.lastName != 'undefined') ? response.invited_user_data.firstName : '',
              email: (typeof response.invited_user_data.email != 'undefined') ? response.invited_user_data.email : '',
              mosaic_id: (typeof response.invited_user_data.global_mosaic_id != 'undefined') ? response.invited_user_data.global_mosaic_id : '',
            };
          }

          dispatch(setUser(user));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(false);
      setBasepath(pathInfo[0]);
    }
  }, []);

  return (
    <>
      {!loading ? (
        <Router basename={`${basepath}`}>
          <Studio baseName={basepath} setBaseName={setBasepath} />
          <Toaster
            position='bottom-right'
            toastOptions={{ className: "react-hot-toast" }}
          />
        </Router>
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
}

export default App;
