    
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import {
    getDesignConfig,
} from '../features/studio/studioSlice';
import { VideoDisplay, Loader } from '../components';
import MainLayout from '@src/layout/MainLayout';
import classnames from 'classnames';

const TwoColumnLayout = forwardRef((props, ref) => {

    const childRef = useRef();
    
    const designConfig = useSelector(getDesignConfig);
    //const bodyBgColor = props.body_bg_color || designConfig.body_bg_color;
    const bodyBgColor = designConfig.body_bg_color;
    
    useImperativeHandle(ref, () => ({
        playVideoRef() {
            childRef.current.playVideoRef()
        }
    }));

    return (  
        <div className='shoutout_wrap'>
            {props.loading && (
                <div style={{ height: '100vh' }}>
                    <Loader loading={true} />
                </div>
            )}
            {!props.loading && (
                <div className={classnames('shoutout_content shoutout_two_column', {
                    'column_overlap': props.columnOverlap
                })}>
                    <VideoDisplay
                        designConfig={designConfig}
                        ref={childRef}
                        playing={(val) => { // eslint-disable-line no-unused-vars
                            if(props.onEnd){
                                props.onEnd(val);
                            }
                        }}
                    />
                    <MainLayout body_bg_color={bodyBgColor} uploadingClass={props.uploadingClass}>
                        {props.children}
                    </MainLayout>
                </div>
            )}
        </div>
    );
});

TwoColumnLayout.defaultProps={
    uploadingClass : ''
}


export default TwoColumnLayout;