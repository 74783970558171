import React from 'react';
import { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const Progress = ({totalsecs}) => {

    const [seconds, setSeconds] = useState(0);
    
    useEffect(() => {
        let myInterval = setInterval(() => {
            setSeconds(seconds + 1);
        }, 1000);
        
        return () => {
            clearInterval(myInterval);
        };
    });

    //console.log(seconds);

    return (
        <CircularProgressbar 
            /* value={totalsecs}  */
            value={seconds / totalsecs * 100} 
            styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Text size
                textSize: '16px',
                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
                // Colors
                pathColor: '#ED1C24',
                textColor: '#f88',
                trailColor: `rgba(255, 255, 255, ${seconds / 120 * 100})`,
                backgroundColor: `rgba(255, 255, 255, ${seconds / 120 * 100})`,
            })}
        />
    );
};
