import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchConfig } from "./studioAPI";
import { isBrowser } from "react-device-detect";

export const ScreenNames = [
  "landing",
  "welcome",
  "textCapture",
  "booth",
  "crop",
  "text",
  "decision",
  "designpreview",
  "legal",
  "thanks",
];

// export type Experiences = 'photo'|'boomerang'|'GIF'|'video';
// export type Status = 'idle'|'counter'|'loading'|'gifCapturing'|'videoCapturing';
// export type Screen = 'landing'|'welcome'|'booth'|'decision'|'frame'|'sticker'|'legal'|'download';
// const FACING_MODE_USER = "user";

let initialState = {
  user: null,
  screen: "landing",
  experience: "video",
  //frame: "./images/shoutout/frame1.png",
  frame: "http://shoutouts_new.localhost:3000/images/shoutout/frame1.png",
  status: "idle",
  testimonial: null,
  profile: null,
  image: null,
  video: null,
  videoCapture: false,
  videoConstraints: isBrowser
    ? {
        facingMode: "user",
        width: { ideal: 1280 },
        height: { ideal: 720 },
      }
    : {
        facingMode: "user",
        width: { ideal: 1280 },
        height: { ideal: 720 },
      },
  previewSize: isBrowser
    ? {
        width: 1280,
        height: 720,
      }
    : {
        width: 720,
        height: 1280,
      },
  screenSize: {
    width: 0,
    height: 0,
  },
  designConfig: {
    body_bg_color: "#ffffff",
    body_text_color: "#000000",
    btn_bg_color: "#48A0F5",
    btn_text_color: "#ffffff",
    frame:
      "https://mystorinet-local.s3.amazonaws.com/uploads/layouts/frame_default.png",
    show_frames: false,
    show_stickers: false,
    feed_tagline: "Checkout this shoutout #stori",
    welcome_video:
      "https://mystorinet-local.s3.amazonaws.com/uploads/layouts/welcome_default.mp4",
    logo: "https://mystorinet-local.s3.amazonaws.com/uploads/layouts/default_logo.png",
  },
  counter: 3,
  canvasState: null,
  thumb: null,
  record: null,
};

let testing = false;
let isMobile = false;
let screen = "welcome";

if (testing) {
  initialState.experience = "video";
  if (initialState.experience == "text") {
    initialState.testimonial = {
      message:
        "",
      firstName: "",
      lastName: "",
      designation: "",
    };
  }
  initialState.user = {
    firstName: "",
    lastName: "",
    email: "",
  };
}

if (testing && isMobile) {
  initialState.video = "/storage/shoutout_media/test/mobile_video.webm";
  initialState.screen = screen;
  initialState.screenSize = {
    width: 393,
    height: 737,
  };
  initialState.previewSize = {
    width: 720,
    height: 1280,
  };
} else if (testing && !isMobile) {
  initialState.video = "/storage/shoutout_media/test/desktop_video.webm";
  initialState.screen = screen;
  initialState.screenSize = {
    width: 1440,
    height: 726,
  };
  initialState.previewSize = {
    width: 1280,
    height: 720,
  };
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(getdesignConfig(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getdesignConfig = createAsyncThunk(
  "studio/fetchConfig",
  async (amount) => {
    const response = await fetchConfig(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const studioSlice = createSlice({
  name: "studio",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDesignConfig: (state, action) => {
      state.designConfig = action.payload;
    },
    setRecordData: (state, action) => {
      state.record = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    changeScreen: (state, action) => {
      state.screen = action.payload;
    },
    setViewport: (state, action) => {
      let pay = action.payload;
      state.screenSize.width = pay.width;
      state.screenSize.height = pay.height;
    },
    setPreviewSize: (state, action) => {
      let pay = action.payload;
      state.previewSize.width = pay.width;
      state.previewSize.height = pay.height;
    },
    setCameraConst: (state, action) => {
      state.videoConstraints = action.payload;
    },
    setExperience: (state, action) => {
      state.experience = action.payload;
    },
    setFrame: (state, action) => {
      state.frame = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setDecision: (state, action) => {
      if (action.payload === "approve") {
        // state.screen = 'editor';
        state.screen = "download";
      }

      if (action.payload === "retake") {
        state.image = null;
        state.video = null;
        state.status = "loading";
        state.screen = "booth";
      }

      if (action.payload === "reset") {
        state.status = "loading";
        state.image = null;
        state.video = null;
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setVideoCaptureStatus: (state, action) => {
      state.videoCapture = action.payload;
    },
    setVideoData: (state, action) => {
      state.video = action.payload;
    },
    changeCameraFace: (state) => {
      state.videoConstraints.facingMode =
        state.videoConstraints.facingMode === "user"
          ? { exact: "environment" }
          : "user";
    },
    setCanvasState: (state, action) => {
      state.canvasState = action.payload;
    },
    setThumb: (state, action) => {
      state.thumb = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setTestimonial: (state, action) => {
      state.testimonial = action.payload;
    },
    resetState(state) {
      let testt = { ...initialState };

      testt.designConfig = state.designConfig;
      testt.user = state.user;

      return {
        ...testt,
      };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getdesignConfig.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getdesignConfig.fulfilled, (state, action) => {
        state.status = "idle";
        state.designConfig = action.payload;
      });
  },
});

export const {
  setUser,
  changeScreen,
  setViewport,
  setPreviewSize,
  setCameraConst,
  setExperience,
  setFrame,
  setImage,
  setDecision,
  setStatus,
  setVideoCaptureStatus,
  setVideoData,
  changeCameraFace,
  setCanvasState,
  setThumb,
  resetState,
  setDesignConfig,
  setProfile,
  setTestimonial,
  setRecordData,
} = studioSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const getDesignConfig = (state) => state.studio.designConfig;
export const getUserData = (state) => state.studio.user;
export const getStatus = (state) => state.studio.status;
export const getVideoData = (state) => state.studio.video;
export const selectScreen = (state) => state.studio.screen;
export const selectExperience = (state) => state.studio.experience;
export const selectFrame = (state) => state.studio.frame;
export const selectImage = (state) => state.studio.image;
export const selectVideoConstraints = (state) => state.studio.videoConstraints;
export const selectScreenSize = (state) => state.studio.screenSize;
export const selectPreviewSize = (state) => state.studio.previewSize;
export const selectCanvasState = (state) => state.studio.canvasState;
export const selectVideoCaptureStatus = (state) => state.studio.videoCapture;
export const selectCapturedVideo = (state) => state.studio.video;
export const selectCounter = (state) => state.studio.counter;
export const selectThumb = (state) => state.studio.thumb;
export const selectProfile = (state) => state.studio.profile;
export const selectTestimonial = (state) => state.studio.testimonial;
export const selectRecord = (state) => state.studio.record;

export default studioSlice.reducer;
