import React from 'react';
import { withOrientationChange, isMobile, isTablet } from 'react-device-detect';

function MobileLandsapeMessage({ isLandscape }) {
    if (isLandscape && (isMobile || isTablet)) {
        return (
            <div className='shoutout_disable_screen'>
                <div className='shoutout_disable_screen_box'>
                    <svg
                        width='368'
                        height='368'
                        viewBox='0 0 368 368'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M99.0361 224.247C100.511 229.49 105.631 233.303 111.076 233.261L257.3 232.147C264.13 232.097 269.661 226.494 269.608 219.643L269.047 147.519C268.997 140.689 263.394 135.157 256.543 135.211L110.325 136.344C103.616 136.4 97.957 142.118 98.0165 148.847L98.5776 220.971C98.5906 222.096 98.7422 223.201 99.0363 224.247L99.0361 224.247ZM259.634 146.785C259.708 147.045 259.744 147.318 259.742 147.599L260.303 219.723C260.321 221.431 258.94 222.826 257.235 222.843L111.017 223.976C109.117 223.805 108.085 222.787 107.897 220.907L107.336 148.783C107.319 147.076 108.699 145.681 110.404 145.664L256.623 144.531C258.047 144.512 259.26 145.461 259.632 146.785L259.634 146.785Z'
                            fill='white'
                        />
                        <path
                            d='M242.218 228.842C242.775 230.821 244.584 232.244 246.734 232.224C249.306 232.205 251.376 230.113 251.355 227.541L250.677 139.906C250.659 137.334 248.566 135.264 245.995 135.285C243.422 135.303 241.353 137.395 241.373 139.967L242.056 227.618C242.053 228.041 242.108 228.45 242.218 228.842L242.218 228.842Z'
                            fill='white'
                        />
                        <path
                            d='M122.396 229.778C122.952 231.756 124.762 233.18 126.911 233.159C129.483 233.141 131.553 231.049 131.532 228.477L130.854 140.842C130.836 138.27 128.744 136.2 126.172 136.22C123.6 136.239 121.53 138.331 121.55 140.903L122.228 228.537C122.226 228.961 122.285 229.387 122.396 229.778L122.396 229.778Z'
                            fill='white'
                        />
                        <path
                            d='M110.3 195.869C110.857 197.847 112.666 199.271 114.816 199.25C117.388 199.232 119.458 197.14 119.437 194.568L119.279 174.901C119.261 172.328 117.168 170.259 114.596 170.279C112.024 170.297 109.954 172.39 109.975 174.961L110.133 194.629C110.134 195.071 110.19 195.478 110.3 195.869L110.3 195.869Z'
                            fill='white'
                        />
                        <path
                            d='M278.425 248.632L278.503 248.911C279.346 251.332 282.003 252.618 284.424 251.775L316.786 240.494L328.066 272.856C328.909 275.277 331.566 276.564 333.987 275.721C336.408 274.878 337.694 272.221 336.851 269.8L324.031 233.038C323.188 230.616 320.532 229.33 318.111 230.173L281.348 242.993C279.035 243.788 277.763 246.28 278.424 248.631L278.425 248.632Z'
                            fill='white'
                        />
                        <path
                            d='M40.6912 225.12C40.6912 225.12 40.6965 225.138 40.6912 225.12C46.1943 244.391 55.3036 262.023 67.7541 277.505C79.7746 292.467 94.4238 304.814 111.244 314.216C128.083 323.612 146.274 329.588 165.333 331.996C185.06 334.479 204.851 332.977 224.145 327.549C235.396 324.383 246.242 319.922 256.371 314.273C266.202 308.787 275.433 302.163 283.796 294.597C291.992 287.156 299.378 278.777 305.755 269.673C312.087 260.622 317.417 250.869 321.608 240.649C322.593 238.278 321.444 235.561 319.075 234.575C316.703 233.591 313.986 234.74 313.001 237.109C296.544 277.156 263.245 306.862 221.618 318.575C147.847 339.331 70.6972 296.27 49.6421 222.583C48.925 220.106 46.3668 218.673 43.8921 219.39C41.4282 220.081 39.9984 222.658 40.6915 225.122L40.6912 225.12Z'
                            fill='white'
                        />
                        <path
                            d='M30.6955 98.4283L30.774 98.7073L43.5941 135.47C44.4371 137.891 47.0933 139.177 49.5145 138.334L86.2771 125.514C88.6983 124.671 89.9845 122.015 89.1415 119.594C88.2985 117.173 85.6423 115.886 83.2211 116.729L50.8593 128.01L39.5786 95.6483C38.7356 93.2272 36.0794 91.9409 33.6582 92.7839C31.3061 93.585 30.0345 96.0776 30.696 98.4285L30.6955 98.4283Z'
                            fill='white'
                        />
                        <path
                            d='M45.8841 130.88C46.2567 132.204 47.2269 133.36 48.5918 133.922C50.9631 134.907 53.6802 133.758 54.6653 131.389C71.0982 91.3276 104.396 61.6249 146.023 49.9125C219.793 29.1559 296.943 72.2176 317.998 145.904C318.715 148.381 321.274 149.814 323.748 149.098C326.206 148.386 327.639 145.828 326.947 143.366L326.942 143.348C321.444 124.095 312.334 106.463 299.883 90.978C287.863 76.0167 273.213 63.6692 256.393 54.2677C239.554 44.8714 221.363 38.8957 202.309 36.5062C182.584 34.0226 162.792 35.5246 143.497 40.9533C132.247 44.1188 121.401 48.5794 111.272 54.2293C101.44 59.7146 92.2097 66.3389 83.8469 73.9049C75.6507 81.3458 68.265 89.7248 61.8876 98.8291C55.5557 107.88 50.226 117.633 46.0343 127.853C45.6343 128.851 45.6074 129.906 45.8804 130.876L45.8841 130.88Z'
                            fill='white'
                        />
                    </svg>
                    <h6>Please keep mobile on portrait mode</h6>
                </div>
            </div>
        );
    }

    return null;
}

export default withOrientationChange(MobileLandsapeMessage);
