import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectImage,
    setImage,
    changeScreen,
    getDesignConfig,
} from '../features/studio/studioSlice';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { useNavigate } from 'react-router-dom';

export const CropPage = () => {
    const cropperRef = useRef(null);
    const [cropper, setCropper] = useState();

    const imgData = useSelector(selectImage);
    const dispatch = useDispatch();
    const designConfig = useSelector(getDesignConfig);

    const navigate = useNavigate();

    const onCancel = (e) => { // eslint-disable-line no-unused-vars
        dispatch(setImage(''));
        dispatch(changeScreen('booth'));
        navigate('/booth');
    };

    const onNext = (e) => { // eslint-disable-line no-unused-vars
        if (typeof cropper !== 'undefined') {
            dispatch(setImage(cropper.getCroppedCanvas().toDataURL()));
            dispatch(changeScreen('text'));
            navigate('/text');
        }
    };

    return (
        <div className='shoutout_wrap'>
            <div className='shoutout_content'>
                <div className='shoutout_content_section'>
                    <div className='shoutout_crop_wrap'>
                        <Cropper
                            src={imgData}
                            style={{ height: 400, width: '100%' }}
                            initialAspectRatio={1}
                            aspectRatio={1}
                            responsive={true}
                            guides={false}
                            ref={cropperRef}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />

                        <div className='buttonWrap'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={onCancel}
                                style={{
                                    backgroundColor: designConfig.btn_bg_color,
                                    borderColor: designConfig.btn_bg_color,
                                    color: designConfig.btn_text_color,
                                }}>
                Prev
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={onNext}
                                style={{
                                    backgroundColor: designConfig.btn_bg_color,
                                    borderColor: designConfig.btn_bg_color,
                                    color: designConfig.btn_text_color,
                                }}>
                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
